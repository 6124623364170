.leaflet-popup-content{
    max-width: 350px;
    margin: 0 !important;

    @media (max-width: 300px) {
      max-width: 300px;
    }

    @media (max-width: 480px) and  (max-height: 700px) {
      max-width: 300px;
    }
  }

  .leaflet-popup-content-wrapper{
    padding: 0 !important;
  }

  .pulse {
    animation: downpulse 1s infinite;
  }

  @keyframes downpulse {
    0% {
          transform: scale(0.95);
      }
  
      70% {
          transform: scale(1.2);
      }
  
      100% {
          transform: scale(0.95);
      }
  }
  
  .soc-med-input{
    // margin-left: -5px;
    transition: left 1s linear;
    z-index: 1;
    &:focus + .sample{
      display: none;
    }
  }
  .sample {
    // margin-top: 8px;
    opacity: 0.5;
    width: max-content;
    position: absolute;
    animation: placeholder-animate 9.5s infinite linear
  }
  
  @keyframes placeholder-animate {
    0% {
          margin-left: 10%;
      }
  
      100% {
          margin-left: -135%;
      }
  }
  
  // * { box-sizing: border-box; }
  $duration: 15s;
  
  @-webkit-keyframes ticker {
    0% {
      -webkit-transform: translate3d(50%, 0, 0);
      transform: translate3d(50%, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  @keyframes ticker {
    0% {
      -webkit-transform: translate3d(50%, 0, 0);
      transform: translate3d(50%, 0, 0);
      visibility: visible;
    }
  
    100% {
      -webkit-transform: translate3d(-112%, 0, 0);
      transform: translate3d(-112%, 0, 0);
    }
  }
  
  .ticker-wrap {
    position: absolute;
    z-index: 5;
    // bottom: 0;
    margin-left: -80px;
    margin-top: -35px;
    width: 158px;
    overflow: hidden;
    height: 50px;
    padding-left: 100%;
    box-sizing: content-box;
  
    .ticker {
      vertical-align: middle;
      display: inline-block;
      height: 50px;
      line-height: 50px;
      white-space: nowrap;
      padding-right: 100%;
      box-sizing: content-box;
  
      -webkit-animation-iteration-count: infinite; 
              animation-iteration-count: infinite;
      -webkit-animation-timing-function: linear;
              animation-timing-function: linear;
     -webkit-animation-name: ticker;
             animation-name: ticker;
      -webkit-animation-duration: $duration;
              animation-duration: $duration;
  
      &__item {
        display: inline-block;
        padding-left: .5rem;
        padding-right: .5rem;
        font-size: 16px;
        color: red;
      }
  
    }
  
  }

  .leaflet-div-icon-marker {
    object-fit: cover;
    overflow: auto;
    z-index: 500;
    overflow: hidden;
    pointer-events: none !important;

    .avatar {
      object-fit: cover;
      width: 27px;
      height: 27px;
      mask-image: url('../assets/images/png/AvatarMaskTemplate.png') !important;
      mask-size: 100%;
      mask-position: center;
      z-index: 20;
      position: absolute;
      pointer-events: auto;
      opacity: 0;
    }
    .avatar-friend {
      object-fit: cover;
      width: 29px;
      height: 29px;
      mask-image: url('../assets/images/png/AvatarMaskTemplate.png') !important;
      mask-size: 100%;
      mask-position: center;
      z-index: 20;
      position: absolute;
      pointer-events: auto;
      margin: 6px 4.25px;
    }

    .avatar-friend-no-avatar {
      object-fit: cover;
      width: 29px;
      height: 29px;
      mask-image: url('../assets/images/png/AvatarMaskTemplate.png') !important;
      mask-size: 100%;
      mask-position: center;
      z-index: 20;
      position: absolute;
      pointer-events: auto;
      opacity: 0;
    }
    .avatar-location-picker {
      object-fit: cover;
      width: 29px;
      height: 29px;
      mask-image: url('../assets/images/png/AvatarMaskTemplate.png') !important;
      mask-size: 100%;
      mask-position: center;
      z-index: 20;
      position: absolute;
      display: none;
      pointer-events: auto;
    }
    .marker-image {
      width: 27.5px;
      height: 46px;
      z-index: 10 !important;
      margin-left: -8px;
      pointer-events: none;
    }

    .marker-image-picker {
      width: 45px !important;
      height: 46px;
      z-index: 10 !important;
      margin-left: -8.8px;
      margin-top: -3.5px;
      pointer-events: none;
    }

    .marker-image-friend {
      width: 35px;
      height: 54px;
      z-index: 10 !important;
      margin-left: -8px;
      pointer-events: none;
    }
    
  }

  .leaflet-marker-icon.leaflet-div-icon-marker {
    width: 38px !important;
    height: 55px !important;
    margin-left: -13px !important;
  }

  // .marker-avatar {
  //   width: 27px;
  //   height: 27px;
  //     mask-image: url('../assets/images/png/AvatarMaskTemplate.png') !important;
  //     mask-size: 100%;
  //     mask-position: center;
    
  // }

  

  
  .leaflet-marker-icon:not(.marker-cluster, .marker-friends, .marker-avatar) {
    // opacity: 0 !important;
      // mask-image: url('../assets/images/png/AvatarMaskTemplate.png') !important;
      // mask-size: 100%;
      // mask-position: center;
      overflow: none;
  }
  
  .marker-cluster {
    opacity: 100 !important;
    background-color: rgb(224, 224, 153) !important;
    div{
      background-color: yellow !important;
    }
  }

  .connector-modal{
    max-width: 650px !important;
    @media (max-width: 600px) {
      max-width: 450px;
    }
  }

  .code-word-toggle-container{
    background-color: white;
    height: 32px;
    width: 30px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    
  }
  
  .toggle-for-code-word{
    margin-top: -2px;
    margin-left: 2px;
    padding-left: 10px;
    padding-right: 15px;
    // background-color: rgb(219, 216, 216);
    color: rgb(190, 190, 190);
    border-radius: 5px;
    height: 25px;
    text-align: center;
    font-size: 25px;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  #code-word-toggle-input{
    display: none;
  }

  #code-word-toggle-input:checked + .toggle-for-code-word{
    // background-color: gray;
    color: black;
  }

  .savetiles {
    display: none;
  }

  .mapmodal {
    cursor: default;
  }

  .mapmodal:active {
    cursor: default;
  }
  
  
  // .chakra-modal__content{
  //   @media (max-width: 600px) {
  //     height: calc(100vh - 190px) !important;
  //   }
  // }
